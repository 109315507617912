<template>
  <CustomBottomSheet
    :refName="'StudentsIdReportFilter'"
    size="xl"
    :headerText="$t('Reports.studentsId')"
    :headerIcon="icon"
    @opened="
      setFilter();
      getDialogs();
    "
  >
    <div class="report-container">
      <div class="row report-filter">
        <CustomSelectBox
          :className="'col-md-6'"
          :id="`userToken`"
          :value="users.filterData.userToken"
          :options="userTokenOptions"
          v-on:changeValue="users.filterData.userToken = $event"
          :title="$t('students.select')"
          :imgName="'students.svg'"
          :isDisabled="isEducationalStageSelected"
        />
        <CustomSelectBox
          :className="'col-md-6'"
          :id="`userEducationalStageToken`"
          :value="users.filterData.userEducationalStageToken"
          :options="userEducationalStageTokenOptions"
          v-on:changeValue="users.filterData.userEducationalStageToken = $event"
          :title="$t('EducationalStages.select')"
          :imgName="'educationalStages.svg'"
          :isDisabled="isUserTokenSelected"
        />
        <CustomInput
          :className="'col-md-6'"
          :id="`cardYear`"
          :value="users.filterData.cardYear"
          v-on:changeValue="users.filterData.cardYear = $event"
          :title="$t('Reports.cardYear')"
          :imgName="'year.svg'"
        />

        <CustomInput
          :className="'col-md-6'"
          :id="`sendTo`"
          :value="users.filterData.sendTo"
          v-on:changeValue="users.filterData.sendTo = $event"
          :title="$t('Reports.sendTo')"
          :imgName="'email.svg'"
        />
      </div>
      <div
        v-if="reportUrl"
        class="row report-result animate__animated animate__fadeInDownBig"
      >
        <embed
          type="application/pdf"
          :src="reportUrl"
          width="400"
          height="300"
          id="pdf"
        />
      </div>
    </div>

    <div class="form-actions">
      <div class="icon-submit" @click.prevent="getReport">
        <img
          src="@/assets/images/check-icon.svg"
          :title="$t('Reports.print')"
        />
      </div>
      <div
        @click.prevent="closeBottomSheet('StudentsIdReportFilter')"
        class="icon-cancel"
      >
        <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
      </div>
    </div>
  </CustomBottomSheet>
</template>

<script>
import CustomBottomSheet from "@/components/general/CustomBottomSheet.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import generalMixin from "@/utils/generalMixin";
import icon from "@/assets/images/students.svg";
import { STATUS } from "@/utils/constants";
import Users from "@/models/users/Users";
import apiUser from "@/api/user";
import { getFullFilePathFromServer } from "@/utils/functions";
import {
  getUsersDialog,
  getEducationalStagesDialog,
} from "@/utils/dialogsOfApi";
import { USER_TYPE } from "@/utils/constantLists";

export default {
  mixins: [generalMixin],
  data() {
    return {
      reportUrl: "",
      isLoading: false,
      users: new Users(),
      icon,
      userTokenOptions: [],
      userEducationalStageTokenOptions: [],
    };
  },
  components: {
    CustomBottomSheet,
    CustomSelectBox,
    CustomInput,
  },
  props: {
    userToken: {
      type: String,
      default: "",
    },
    userEducationalStageToken: {
      type: String,
      default: "",
    },
  },
  computed: {
    isUserTokenSelected() {
      let status = false;
      if (this.users.filterData.userToken) status = true;
      return status;
    },
    isEducationalStageSelected() {
      let status = false;
      if (this.users.filterData.userEducationalStageToken) status = true;
      return status;
    },
  },
  methods: {
    setFilter() {
      this.reportUrl = "";
      this.users.filterData.setInitialValue();
      this.users.filterData.userToken = this.userToken;
      this.users.filterData.userEducationalStageToken =
        this.userEducationalStageToken;
    },
    async getDialogs() {
      this.getUsersDialog();
      this.getEducationalStagesDialog();
    },

    async getUsersDialog() {
      this.isLoading = true;
      this.$emit("isLoading", true);
      let params = {
        modelName: "students",
        userTypeToken: USER_TYPE.Student,
      };

      this.userTokenOptions = await getUsersDialog(params);
      this.isLoading = false;
      this.$emit("isLoading", false);
    },
    async getEducationalStagesDialog() {
      this.isLoading = true;
      this.userEducationalStageTokenOptions =
        await getEducationalStagesDialog();
      this.isLoading = false;
    },

    async getReport() {
      this.$emit("isLoading", true);
      this.reportUrl = "";
      try {
        if (this.isUserTokenSelected) {
          this.users.filterData.userEducationalStageToken = "";
        }
        if (this.isEducationalStageSelected) {
          this.users.filterData.userToken = "";
        }
        const response = await apiUser.getStudentsIdReport(
          this.users.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          if (response.data.reportPath) {
            this.reportUrl = getFullFilePathFromServer(
              response.data.reportPath
            );
          } else {
            this.showMsg(this.$t("Reports.noReports"));
          }
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        console.log(error);
        this.showMsg(this.$t("errorCatch"));
      }
      this.$emit("isLoading", false);
    },
    async created() {},
  },
};
</script>
