var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.educationalStagesData)?_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("#")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.$t("EducationalStages.data")))]),_vm._m(0)]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("general.image")))]),_c('th',[_vm._v(_vm._s(_vm.$t("general.code")))]),_c('th',{staticClass:"cell-lg"},[_vm._v(_vm._s(_vm.$t("general.name")))])])]),_c('tbody',_vm._l((_vm.educationalStagesData),function(educationalStage,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index + _vm.filterData.currentIndex))]),_c('td',[_c('img',{staticClass:"item-img-table",attrs:{"src":_vm.fullPathFileFromServer(
                educationalStage.educationalStageImagePath,
                _vm.defaultImg
              ),"onerror":("this.src='" + _vm.defaultImg + "'")}})]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(educationalStage.fullCode)))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(educationalStage.educationalStageNameCurrent))+" ")]),_c('td',{staticClass:"fmenu-item-container"},[_c('FloatingMenu',[(_vm.checkPrivilege(_vm.hasStudentsIdReport()))?_c('li',[_c('button',{attrs:{"title":_vm.$t('Reports.studentsId')},on:{"click":function($event){_vm.setEducationalStageData(educationalStage);
                  _vm.openBottomSheet('StudentsIdReportFilter');}}},[_c('img',{attrs:{"src":require("@/assets/images/cards.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasStudentsWalletReport()))?_c('li',[_c('button',{attrs:{"title":_vm.$t('Reports.studentsWallet')},on:{"click":function($event){_vm.setEducationalStageData(educationalStage);
                  _vm.openBottomSheet('StudentsWalletReportFilter');}}},[_c('img',{attrs:{"src":require("@/assets/images/report.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasStudent()))?_c('li',[_c('router-link',{attrs:{"to":{
                  name: 'Students',
                  params: {
                    userToken: ' ',
                    userEducationalStageToken:
                      educationalStage.educationalStageToken,
                  },
                },"title":_vm.$t('students.modelName')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/students.svg")}})])],1):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setEducationalStageData(educationalStage);
                  _vm.openBottomSheet('EducationalStageInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),_c('li',[_c('button',{attrs:{"title":_vm.$t('general.qrCode')},on:{"click":function($event){_vm.setEducationalStageData(educationalStage);
                  _vm.openBottomSheet('EducationalStageQRCode');}}},[_c('img',{attrs:{"src":require("@/assets/images/qr-code.svg")}})])]),(_vm.checkPrivilege(_vm.hasEducationalStageEdit()))?_c('li',[_c('button',{attrs:{"title":_vm.$t('edit')},on:{"click":function($event){_vm.setEducationalStageData(educationalStage);
                  _vm.openBottomSheet('EducationalStageUpdate');}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasEducationalStageFinaleDelete()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.EducationalStageDelete",modifiers:{"EducationalStageDelete":true}}],attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setEducationalStageData(educationalStage)}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasEducationalStageChangeActivationType()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.EducationalStageChangeActivationType",modifiers:{"EducationalStageChangeActivationType":true}}],attrs:{"title":_vm.$t('changeActivationType')},on:{"click":function($event){return _vm.setEducationalStageData(educationalStage)}}},[_c('img',{attrs:{"src":require("@/assets/images/changeActivationType.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setEducationalStageData(educationalStage);
                  _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])],1)])}),0)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{attrs:{"rowspan":"2"}},[_c('i',{staticClass:"fas fa-sliders-h"})])}]

export { render, staticRenderFns }