var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row custom-cards"},_vm._l((_vm.educationalStagesData),function(educationalStage,index){return _c('CustomCard',{key:index,attrs:{"className":'col-sm-6 col-md-4 col-lg-3',"title":educationalStage.educationalStageNameCurrent,"description":educationalStage.fullCode,"imagePath":educationalStage.educationalStageImagePath,"defaultImg":_vm.defaultImg,"index":++index + _vm.filterData.currentIndex}},[(_vm.checkPrivilege(_vm.hasStudentsIdReport()))?_c('li',[_c('button',{attrs:{"title":_vm.$t('Reports.studentsId')},on:{"click":function($event){_vm.setEducationalStageData(educationalStage);
          _vm.openBottomSheet('StudentsIdReportFilter');}}},[_c('img',{attrs:{"src":require("@/assets/images/cards.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasStudentsWalletReport()))?_c('li',[_c('button',{attrs:{"title":_vm.$t('Reports.studentsWallet')},on:{"click":function($event){_vm.setEducationalStageData(educationalStage);
          _vm.openBottomSheet('StudentsWalletReportFilter');}}},[_c('img',{attrs:{"src":require("@/assets/images/report.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasStudent()))?_c('li',[_c('router-link',{attrs:{"to":{
          name: 'Students',
          params: {
            userToken: ' ',
            userEducationalStageToken: educationalStage.educationalStageToken,
          },
        },"title":_vm.$t('students.modelName')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/students.svg")}})])],1):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setEducationalStageData(educationalStage);
          _vm.openBottomSheet('EducationalStageInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),_c('li',[_c('button',{attrs:{"title":_vm.$t('general.qrCode')},on:{"click":function($event){_vm.setEducationalStageData(educationalStage);
          _vm.openBottomSheet('EducationalStageQRCode');}}},[_c('img',{attrs:{"src":require("@/assets/images/qr-code.svg")}})])]),(_vm.checkPrivilege(_vm.hasEducationalStageEdit()))?_c('li',[_c('button',{attrs:{"title":_vm.$t('edit')},on:{"click":function($event){_vm.setEducationalStageData(educationalStage);
          _vm.openBottomSheet('EducationalStageUpdate');}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasEducationalStageFinaleDelete()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.EducationalStageDelete",modifiers:{"EducationalStageDelete":true}}],attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setEducationalStageData(educationalStage)}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasEducationalStageChangeActivationType()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.EducationalStageChangeActivationType",modifiers:{"EducationalStageChangeActivationType":true}}],attrs:{"title":_vm.$t('changeActivationType')},on:{"click":function($event){return _vm.setEducationalStageData(educationalStage)}}},[_c('img',{attrs:{"src":require("@/assets/images/changeActivationType.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setEducationalStageData(educationalStage);
          _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }